import VueLazyload from 'vue-lazyload'
import Vue from 'vue'
import Vuex from 'vuex'
import UniqueId from 'vue-unique-id'
import RequestsPlugin from './plugins/requests'
import CopyDirective from './directives/copy'
import Sticky from 'vue-sticky-directive'

Vue.prototype.$vuescrollConfig = {
    vuescroll: {
        scroller: {
            bouncing: {
                top: 0,
                bottom: 0,
                left: 50,
                right: 50
            }
        },
        mode: 'slide',
        zooming: false,
        preventDefault: true
    },
    bar: { background: 'rgba(255,255,255,0)' }
}
Vue.component('vuescroll', () => import(/* webpackChunkName: "vuescroll"*/ 'vuescroll'))
Vue.component('hooper', () => {
    import(/* webpackChunkName: "hooper"*/ 'hooper/dist/hooper.css')
    return import(/* webpackChunkName: "hooper"*/ 'hooper').then(Hooper => Hooper.Hooper)
})
Vue.component('hooper-slide', () => import(/* webpackChunkName: "hooper"*/ 'hooper').then(Hooper => Hooper.Slide))
Vue.component('hooper-navigation', () =>
    import(/* webpackChunkName: "hooper"*/ 'hooper').then(Hooper => Hooper.Navigation)
)
Vue.component('hooper-pagination', () =>
    import(/* webpackChunkName: "hooper"*/ 'hooper').then(Hooper => Hooper.Pagination)
)
Vue.component('hooper-progress', () => import(/* webpackChunkName: "hooper"*/ 'hooper').then(Hooper => Hooper.Progress))
Vue.component('slide-up-down', () => import(/* webpackChunkName: "slide-up-down"*/ 'vue-slide-up-down'))
Vue.component('tags', () => import(/* webpackChunkName: "tags"*/ './components/Tags.vue'))
Vue.component('animated-number', () =>
    import(/* webpackChunkName: "animated-number"*/ './components/AnimatedNumber.vue')
)
Vue.component('audio-player', () => import(/* webpackChunkName: "audio-player"*/ './components/AudioPlayer.vue'))
Vue.component('scoreboards', () => import(/* webpackChunkName: "scoreboards"*/ './components/Scoreboards.vue'))
Vue.component('mswitch', () => import(/* webpackChunkName: "mswitch"*/ './components/Switch.vue'))
Vue.component('ad', () => import(/* webpackChunkName: "mswitch"*/ './components/Ad.vue'))
Vue.component('fetch-btn', () => import(/* webpackChunkName: "fetch-btn"*/ './components/FetchBtn.vue'))
Vue.component('realtime', () => import(/* webpackChunkName: "realtime"*/ './components/Realtime.vue'))
Vue.component('facebook-comments', () =>
    import(/* webpackChunkName: "facebook-comments"*/ './components/FacebookComments.vue')
)
Vue.component('scroll-progress-bar', () =>
    import(/* webpackChunkName: "scroll-progress-bar"*/ './components/ScrollProgressBar.vue')
)
Vue.component('infinite-loading', () => import(/* webpackChunkName: "infinite-loading"*/ 'vue-infinite-loading'))
Vue.component('live-box', () => import(/* webpackChunkName: "live-box" */ './components/LiveBox.vue'))
Vue.component('raw-html', () => import(/* webpackChunkName: "raw-html"*/ './components/RawHtml'))
Vue.directive('copy', CopyDirective)

Vue.use(Sticky)
Vue.use(UniqueId)
Vue.use(VueLazyload, {
    attemp: 3
})
Vue.use(RequestsPlugin)
Vue.use(Vuex)

let BBCL = (window.BBCL = window.BBCL || {})
let mixins = (BBCL.mixins = BBCL.mixins || [])
let plugins = (BBCL.plugins = BBCL.plugins || [])
let asyncs = (BBCL.asyncs = BBCL.asyncs || [])
const store = new Vuex.Store({})

document.addEventListener('DOMContentLoaded', function() {
    Promise.all(asyncs).then(() => {
        plugins.forEach(plugin => {
            plugin(Vue, store)
        })

        const bbclApp = document.getElementById('app')

        if (bbclApp) {
            BBCL.vm = new Vue({
                el: '#app',
                mixins,
                store
            })
        }

        BBCL.vms = Array.from(document.querySelectorAll('.bbcl-app')).map(
            bbclApp =>
                new Vue({
                    el: bbclApp,
                    mixins,
                    store
                })
        )
        // eslint-disable-next-line no-console
        console.log('BBCL loaded')
    })
})
